export default {
    serverConfig: {
        serverBaseUrl: null,
        assetsBaseUrl: null,
    },
    projectConfig: {
        guid: null,
        databaseVersion: null,
        databaseVersionInt: null,
        project_name: null
    },
    userConfig: {
        ownerEmail: null,
        ownerId: null,
        token: null
    },
    readonly_shared_with: [],
    shared_with: [],
    floors: []
}