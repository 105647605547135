export function convertVersionStringToInt(versionString) {
    const versionPieces = versionString.split('.');

    let versionInt =  versionPieces[0] * 1000000000;
    versionInt += versionPieces[1] * 1000000;
    if(versionPieces.length >= 2){
        versionInt += versionPieces[2] * 1000;
    }

    return versionInt;
}