export const VERSION_BORDERS = [
    0, // 0 - 2001000999
    2001001000
];

export function getHandlerByVersionInt(versionInt) {
    let handlerVersionIndex = 0;
    while (
        versionInt > VERSION_BORDERS[handlerVersionIndex]
        &&
        handlerVersionIndex < VERSION_BORDERS.length
        ) {
        handlerVersionIndex++;
    }
    return import("@webviewer/webviewer_v" + (handlerVersionIndex) + "/dist/handler");
}